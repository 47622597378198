var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"cause-icon",style:('fill: ' +
            _vm.icon.color +
            ';border-color:' +
            _vm.icon.color +
            ';' +
            'width:' +
            _vm.size +
            'px; height: ' +
            _vm.size +
            'px; border-radius: ' +
            parseInt(_vm.size) / 2 +
            'px; padding: ' +
            (_vm.$vuetify.breakpoint.xs && _vm.size == '30'
              ? '3'
              : parseInt(_vm.size) / 5) +
            'px'),domProps:{"innerHTML":_vm._s(_vm.compiledSvg)}},'div',attrs,false),on))]}}])},[_c('span',[_vm._v(_vm._s(_vm.name))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }